// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-session-2-js": () => import("./../src/pages/session-2.js" /* webpackChunkName: "component---src-pages-session-2-js" */),
  "component---src-pages-session-3-js": () => import("./../src/pages/session-3.js" /* webpackChunkName: "component---src-pages-session-3-js" */)
}

